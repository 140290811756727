import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Typography } from "antd";
import { PHONENUMBERREGEX } from "src/constants/common";
import { RequestedDidNumberType } from "src/constants/subscription";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import { formatNumberStringEN } from "src/utils/format";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface IEditNumberBlock {
  form: any;
  i: number;
  j: number;
  numDids: number;
  numberType?: string;
  initialLoad: boolean;
}

const EditNumberBlock = ({
  form,
  i,
  j,
  numDids,
  numberType,
  initialLoad,
}: IEditNumberBlock) => {
  const { t } = useTranslation();

  const didRequiredFields = {
    addNumber: true,
    specialRequests: false,
  };

  useEffect(() => {
    if (initialLoad) return;

    const requestedNumber = form.getFieldValue(`user_${i}_did_${j}_requestedNumber`);

    if (numberType === RequestedDidNumberType.REQUESTED) {
      form.setFieldValue(
        `user_${i}_did_${j}_requestedNumber`,
        t(
          "subscribeStep0.requestedNumber",
          { number: requestedNumber }
        )
      );
    }
  }, [initialLoad, numberType]);

  const validatePhoneNumber = () => {
    const number = form.getFieldValue(`user_${i}_did_${j}_addNumber`);
    const trimmedValue = number ? number.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (!PHONENUMBERREGEX.test(trimmedValue)) {
      return Promise.reject(new Error(t("fields.addNumberRestriction")));
    }

    if (trimmedValue.length !== 10) {
      return Promise.reject(new Error(t("fields.addNumberLength")));
    }

    for (let k = 1; k <= numDids; k++) {
      if (k !== j) {
        const value = form.getFieldValue(`user_${i}_did_${k}_addNumber`);
        if (value === trimmedValue) {
          return Promise.reject(new Error(
            t(
              "fields.addNumberAlreadyInUse",
              { number: capitalizeFirstLetter(formatNumberStringEN(k)) }
            )
          ));
        }
      }
    }

    return Promise.resolve();
  };

  const reviewInputBlock = (
    label: string,
    rules: any[],
    required: boolean,
    name: string,
    placeholder: string = "",
    disabled: boolean = false,
  ) => (
    <div className={styles.review_block}>
      <Text className={styles.review_block_label}>
        {capitalizeFirstLetter(formatNumberStringEN(i))}
        {" "}User - {label}
      </Text>
      <Form.Item
        name={name}
        rules={rules}
        className={
          `${styles.sign_up_form_item} ${
            required ? styles.required : ""
          }`
        }
      >
        <Input
          type="text"
          placeholder={placeholder}
          className={styles.sign_up_form_input}
          disabled={disabled}
        />
      </Form.Item>
    </div>
  );

  return (
    <>
      {numberType === RequestedDidNumberType.EXISTING ? (
        reviewInputBlock(
          t("subscribeStep5.phoneNumberExisting"),
          [
            {
              required: didRequiredFields.addNumber,
              message: t("fields.addNumberRequired")
            },
            { validator: () => validatePhoneNumber() },
          ],
          didRequiredFields.addNumber,
          `user_${i}_did_${j}_addNumber`,
          t("fields.addNumber")
        )
      ) : (
        numberType === RequestedDidNumberType.LOCAL ||
        numberType === RequestedDidNumberType.TOLLFREE
      ) ? (
        reviewInputBlock(
          t("fields.specialRequests"),
          [],
          didRequiredFields.specialRequests,
          `user_${i}_did_${j}_specialRequests`,
          t("fields.specialRequests")
        )
      ) : numberType === RequestedDidNumberType.REQUESTED ? (
        reviewInputBlock(
          t("fields.specialRequests"),
          [],
          didRequiredFields.specialRequests,
          `user_${i}_did_${j}_requestedNumber`,
          t("fields.specialRequests"),
          true
        )
      ) : <></>}
      <Form.Item
        name={`user_${i}_did_${j}_numberType`}
        style={{ display: 'none' }}
      ></Form.Item>
    </>
  );
};

export default EditNumberBlock;
