import {
  Layout,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import Colors from "src/themes/colors";
import MessageBubble from "./messageBubble";

const { Content } = Layout;
const { Text } = Typography;

interface IConversationMessages {
  conversationUser: any;
  messages: any;
  scrollRef: any;
  isLoading: boolean;
}

const ConversationMessages = ({
  conversationUser,
  messages,
  scrollRef,
  isLoading,
}: IConversationMessages) => {
  const { t } = useTranslation();

  return (
    <Content className="conversation-message">
      <Spin
        style={{ height: '100%', width: '100%' }}
        spinning={isLoading}
      >
        {scrollRef && messages && (
          <div ref={scrollRef} className="message-box pt-3">
            {messages?.items?.map((message: any, key: number) => (
              <MessageBubble
                key={key}
                userId={message.userId}
                username={message.username}
                fileLocations={message.fileLocations}
                content={message.content}
                createdAt={message.createdAt}
              />
            ))}
          </div>
        )}

        {conversationUser?.isBlocked && (
          <Row style={{ background: Colors.Secondary }}>
            <Space className="w-100 d-flex align-items-center justify-content-center">
              <Text style={{ textAlign: "center", color: Colors.white }}>
                {t("common.userBlocked")}
              </Text>
            </Space>
          </Row>
        )}
      </Spin>
    </Content>
  );
};

export default ConversationMessages;
