import { FileZipOutlined } from "@ant-design/icons";

import { Col, Row } from "antd";
import { EMAILREGEX, URLREGEX } from "src/constants/common";
import { formattedTime, isValidFile } from "src/constants/functions";
import Colors from "src/themes/colors";

interface IMessageBubble {
  userId?: any;
  username?: string;
  fileLocations?: string[];
  content: string;
  createdAt?: string;
}

const MessageBubble = ({
  userId,
  username,
  fileLocations,
  content,
  createdAt,
}: IMessageBubble) => {
  const convertMsgLink = (msg: string): string => {
    if (msg) {
      const urlRegex = URLREGEX;
      const urlMatch = urlRegex.exec(msg);
      if (urlMatch) {
        const url = urlMatch[0];
        const beforeUrl = msg.slice(0, urlMatch.index);
        const afterUrl = msg.slice(urlMatch.index + url.length);
        return convertMsgLink(beforeUrl) + `<a href="${url}" target="_blank">${url}</a>` + convertMsgLink(afterUrl);
      } else {
        const emailRegex = EMAILREGEX;
        return msg.replace(emailRegex, '<a href="mailto:$1">$1</a>');
      }
    }
    return '';
  };

  return (
    <div
      style={{
        padding: "0.5rem 3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: userId ? "end" : "start",
      }}
    >
      <div
        style={{
          background: userId ? Colors.senderBg : Colors.reciverBg,
        }}
        className="message"
      >
        <Row style={{ display: "flex" }}>
          {fileLocations && fileLocations.map(
            (i: string, index: number) => isValidFile(i) ? (
              <div key={index} style={{ margin: "0.1rem" }}>
                <Col span={2}>
                  <img
                    key={Math.random()}
                    onClick={() => {
                      window.open(i, "_blank");
                    }}
                    style={{
                      height: "5rem",
                      cursor: "pointer",
                      maxWidth: "10rem",
                      objectFit: "contain",
                    }}
                    src={i}
                  />
                </Col>
              </div>
            ) : (
              <div
                onClick={() => {
                  window.open(i, "_blank");
                }}
                style={{ cursor: "pointer" }}
              >
                <Col span={2}>
                  <FileZipOutlined
                    style={{
                      fontSize: "5rem",
                      color: Colors.Gray,
                    }}
                  />
                </Col>
              </div>
            )
          )}
        </Row>

        <p
          className="message-text"
          dangerouslySetInnerHTML={{ __html: convertMsgLink(content) }}
        ></p>

        <p className="message-meta">
          <span
            style={{ marginRight: "0px", marginTop: "0.2rem" }}
          >
            {createdAt?.split("T")[0]}{" "}
            {createdAt &&
              formattedTime(createdAt)}{" "}
          </span>
        </p>
        <p className="message-meta">
          {username ?? ""}
        </p>
      </div>
    </div>
  );
};

export default MessageBubble;
