import { CloseCircleFilled, SearchOutlined } from "@ant-design/icons";
import {
  Col,
  Flex,
  Form,
  Input,
  Menu,
  MenuProps,
  Modal,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
  notification,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MSG_CHAR_LIMIT, MSG_UNIT_LIMIT, PAGINATION, PhoneNumberRegex, maxSize } from "src/constants/common";
import { returnElipsesText } from "src/constants/functions";
import { firstName } from "src/constants/add-variable";
import Colors from "src/themes/colors";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import {
  bulkContactData,
  bulkContactLoading,
} from "src/store/selectors/features/bulkContacts";
import { getMessageLoadingState } from "src/store/selectors/features/message";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getTemplateData } from "src/store/selectors/features/template";
import { uploadFilesLoading } from "src/store/selectors/features/uploadFiles";
import { getUser } from "src/store/selectors/features/user";
import RequestAppAction from "src/store/slices/appActions";
import { AddBulkScheduleMessageModal } from "../bulk-schedule-mesage-modal";
import ModalButtonContainer from "../modal-button-container";
import TemplateBtn from "../modal-button-container/TemplateBtn";
import ScheduleBtn from "../modal-button-container/ScheduleBtn";
import AttachmentBtn from "../modal-button-container/AttachmentBtn";
import AddVariableBtn from "../modal-button-container/add-variable/AddVariableBtn";
import { replaceContentVariables } from "../modal-button-container/add-variable/utils";
import SendButton from "../button/sendButton";
import "../../pages/conversations/converstions.scss";

const { Text } = Typography;

interface modalProps {
  openModal: (text?: string) => void;
  closeModal: () => void;
}

interface IBulkMessageModal {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  userData?: any;
  loading: boolean;
  setLoading: (value: boolean) => void;
  modalRef?: any;
  isEdit?: boolean;
  ref?: any;
}

const BulkMessageModal = ({
  isModalVisible,
  setIsModalVisible,
  userData,
  loading,
  setLoading,
  modalRef,
  isEdit = false,
  ref,
}: IBulkMessageModal) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = useForm();
  const { width } = useWindowDimensions();

  const contacts: any = useSelector(bulkContactData);
  const did = useSelector(getCurrentDidId);
  const user: any = useSelector(getUser);
  const templates: any = useSelector(getTemplateData);
  const bulkLoading = useSelector(bulkContactLoading);
  const isLoading = useSelector(getMessageLoadingState);
  const spinning = useSelector(uploadFilesLoading);

  const textareaRef: any = useRef<any>(null);
  const uploadFileRef: any = useRef<HTMLInputElement | null>(null);
  const scheduleBulkMessageRef = useRef<modalProps>(null);

  const [units, setUnits] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [attachments, setAttachments] = useState<any>([]);
  const [characters, setCharacters] = useState<number>(0);
  const [totalCharacters, setTotalCharacters] = useState<String>("");
  const [message, setMessage] = useState<string>("");
  const [variableList, setVariableList] = useState<any>([]);
  const [selectedContacts, setSelectedContacts] = useState<any>([]);
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<any>([]);
  const [msgContent, setMsgContent] = useState("");
  const [bulkContacts, setBulkContacts] = useState([]);
  const [newMessage, setNewMessage] = useState(false);
  const [scheduleMsgState, setScheduleMsgState] = useState(false);

  useEffect(() => {
    if (PhoneNumberRegex.test(value) && contacts?.items?.length < 1) {
      setSelectedContacts([
        { firstName: undefined, phoneNumber: value, temp: true },
      ]);
    } else {
      setSelectedContacts(contacts?.items ?? []);
    }
  }, [contacts]);

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      form.resetFields();
      setCharacters(0);
      setUnits(0);
      modalRef?.current?.closeModal();
      setMsgContent("");
    },
  }));

  useEffect(() => {
    if (isEdit && textareaRef?.current) {
      const content = form.getFieldValue("content");
      setTotalCharacters(content);
      setMessage(content);
    }
  }, [form, isEdit]);

  useEffect(() => {
    const variables = [];
    variables.push(firstName);
    setVariableList(variables);
  }, []);

  useEffect(() => {
    const charactersLength = totalCharacters.length;
    const totalUnits = Math.floor(charactersLength / 160);
    const chr = charactersLength - (totalUnits || 0) * 160;

    if (charactersLength === 0 && attachments.length === 0) {
      setUnits(0);
    } else if (charactersLength > 0) {
      let val = totalUnits + 1 + attachments?.length * 3;
      setUnits(val);
    } else {
      let val = attachments?.length * 3;
      setUnits(val);
    }

    setCharacters(chr);
  }, [totalCharacters, attachments]);

  const onTextChange = (value: string) => {
    setMessage(value);
    setTotalCharacters(value.trim());
  }

  const handleTextChange = async (e: any) => {
    onTextChange(e.target.value);
  };

  const handleCancel = () => {
    form.resetFields();
    setCharacters(0);
    setUnits(0);
    setAttachments([]);
    setIsModalVisible(false);
    setTotalCharacters("");
    setMessage("");
    setMsgContent("");
  };

  useEffect(() => {
    const charactersLength = inputValue?.length;
    const totalUnits = Math.floor(charactersLength / 160);

    if (charactersLength === 0) {
      setUnits(0);
    } else {
      setUnits(totalUnits);
    }
  }, [inputValue]);

  const sendMessage = async (values: any) => {
    setLoading(true);

    const phoneNumber = Array.isArray(values?.phoneNumber)
      ? values?.phoneNumber
      : [values?.phoneNumber];

    const updatedArr = phoneNumber?.map((i: string) =>
      i?.startsWith("+1")
        ? i
        : i?.startsWith("1") && i?.length === 11
        ? `+${i}`
        : `+1${i}`
    );

    try {
      await form.validateFields();
      const data: any = {
        content: replaceContentVariables(values?.content, variableList),
        ...values,
        units: units,
        phoneNumber: updatedArr,
        variableList: variableList,
      };
      if (attachments.length > 0) {
        data["fileLocations"] = attachments;
      }

      if (!did) {
        notification.error({
          message: t("notification.sendMessageFail") + '. ' + t("notification.tryRefresh")
        });
        setLoading(false);
        return;
      }

      dispatch(
        RequestAppAction.postMultipleMessage({
          id: did,
          data: data,
          cbSuccess: () => {
            handleCancel();
            notification.success({ message: t("notification.success") });
            // dispatch(RequestAppAction.getUser({ id: user?.id }));
            dispatch(RequestAppAction.fetchConversations({ id: did }));
            // dispatch(RequestAppAction.fetchConversationsById({ id: did }));
            setLoading(false);
          },
          cbFailure: (e: string) => {
            notification.error({
              message: e ?? t("notification.sendMessageFail")
            });
            setLoading(false);
          },
        })
      );
    } catch (e: any) {
      setLoading(false);
      return e;
    }
  };

  // useEffect(() => {
  //   if (did) {
  //     dispatch(
  //       RequestAppAction.fetchBulkContacts({
  //         id: did,
  //         meta: { ...PAGINATION },
  //         cbFailure: (e: string) => {
  //           notification.error({
  //             message: e ?? t("notification.fetchContactFail")
  //           });
  //         },
  //       })
  //     );
  //   }
  // }, [did]);

  const fetchContacts = async (text: string) => {
    try {
      setLoading(true);
      setIsSearching(false);
      dispatch(
        RequestAppAction.fetchBulkContacts({
          id: did,
          meta: { ...PAGINATION, search: text },
          cbFailure: (e: string) => {
            notification.error({
              message: e ?? t("notification.fetchContactFail")
            });
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const handleMenuItemClick = (item: any) => {
    form.setFieldsValue({
      content: item.content,
    });
  };

  const items: MenuProps["items"] = templates?.items?.map(
    (template: { content: any; title: string }, index: any) => ({
      key: String(index),
      label: template.title,
      content: template?.content,
    })
  );

  const menu = () => (
    <Menu>
      {items?.map((menuItem: any, index: number) => (
        <Menu.Item onClick={() => handleMenuItemClick(menuItem)} key={index}>
          {menuItem.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const debounceFetch = async (text: string) => {
    fetchContacts(text);
  };

  const handleSearch = async (text: any) => {
    await debounceFetch(text);
  };

  useEffect(() => {
    return () => {
      setInputValue("");
      setUnits(0);
    };
  }, []);

  const upload = (e: any) => {
    const formData = new FormData();
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSize) {
        // Handle error: File size exceeds 5MB
        notification.error({
          message: t("common.fileSizeError", { name: file.name }),
        });

        return;
      }
    }

    for (const file of files) {
      formData.append("files", file);
    }

    dispatch(
      RequestAppAction.uploadFiles({
        data: formData,
        cbSuccess: (res: any) => {
          uploadFileRef.current.value = "";
          setAttachments((pre: any) => [...pre, ...res?.data]);
        },
        cbFailure: (e: string) => {
          uploadFileRef.current.value = "";
          notification.error({
            message: e ?? t("notification.uploadFileFail")
          });
        },
      })
    );
  };

  useEffect(() => {
    if (!isModalVisible && did && !contacts) {
      dispatch(
        RequestAppAction.fetchBulkContacts({
          id: did,
          meta: { ...PAGINATION },
          cbFailure: (e: string) => {
            notification.error({
              message: e ?? t("notification.fetchContactFail")
            });
          },
        })
      );
    }

    return () => {
      setUnits(0);
      setCharacters(0);
    };
  }, [isModalVisible]);

  const [value, setValue] = useState<string>("");
  const [isSearching, setIsSearching] = useState(false);
  const selectRef = useRef<any>(null);

  useEffect(() => {
    if (did && isModalVisible) {
      setIsSearching(true);
      const debounceTimeout = setTimeout(async () => {
        handleSearch(value);
      }, 800);

      return () => clearTimeout(debounceTimeout);
    }
  }, [value]);

  useEffect(() => {
    if (userData) {
      const num = userData?.phoneNumber;
      form.setFieldValue("phoneNumber", num);
      setNewMessage(true);
    }
  }, [userData]);

  useEffect(() => {
    if (newMessage) {
      setSelectedPhoneNumbers([form.getFieldValue("phoneNumber")]);
      setNewMessage(false);
    }
  }, [newMessage]);

  useEffect(() => {
    const numbers = form.getFieldValue("phoneNumber");
    const contactNames: any = [];
    contacts?.items?.map(
      (i: { phoneNumber: string; firstName: string; lastName: string }) => {
        if (numbers?.includes(i?.phoneNumber)) {
          contactNames?.push({
            phoneNumber: i?.phoneNumber,
            name: i?.firstName
              ? i?.firstName + (i?.lastName || "")
              : i?.phoneNumber,
          });
        }
      }
    );
    setBulkContacts(contactNames ?? []);
  }, [contacts]);

  const openScheduleMsgModal = () => {
    setScheduleMsgState(true);

    setMsgContent(form.getFieldValue("content"));
  };

  useEffect(() => {
    if (scheduleMsgState && selectedPhoneNumbers) {
      handleCancel();
      setTimeout(() => {
        scheduleBulkMessageRef?.current?.openModal(
          t("heading.scheduleMessage")
        );
        setScheduleMsgState(false);
      }, 100);
    }
  }, [scheduleMsgState]);

  const handleContactSelectChange = () => {
    // selectRef.current?.blur();
    const numbers = form.getFieldValue("phoneNumber");
    if (selectedPhoneNumbers !== numbers) {
      setSelectedPhoneNumbers(numbers);
    }
  };

  const handleContactSelect = () => {
    // selectRef.current?.blur();
    const numbers = form.getFieldValue("phoneNumber");
    if (selectedPhoneNumbers !== numbers) {
      setSelectedPhoneNumbers(numbers);
    }

    //   const contactNames: any = [];
    //   contacts?.items?.map(
    //     (i: {
    //       phoneNumber: string;
    //       firstName: string;
    //       lastName: string;
    //     }) => {
    //       if (numbers?.includes(i?.phoneNumber)) {
    //         contactNames?.push({
    //           phoneNumber: i?.phoneNumber,
    //           name: i?.firstName + (i?.lastName || ""),
    //         });
    //       }
    //     }
    //   );

    // dispatch(
    //   RequestAppAction.fetchBulkContacts({
    //     id: did,
    //     meta: { ...PAGINATION },
    //     cbFailure: (e: string) => {
    //       notification.error({
    //         message: e ?? t("notification.fetchContactFail")
    //       });
    //     },
    //   })
    // );
  };

  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={handleCancel}
      title={
        <h1 className="font-size-24 font-weight-500">
          {t("modalHeading.newMessage")}
        </h1>
      }
      style={{ borderRadius: 50 }}
      className="add-convertion-modal"
      footer={[]}
    >
      <input
        ref={uploadFileRef}
        onChange={(e) => {
          if (attachments?.length < 7) {
            upload(e);
          }
        }}
        style={{ display: "none" }}
        type="file"
        multiple
      />
      <Spin spinning={spinning}>
        <Form form={form} onFinish={sendMessage}>
          <Flex vertical gap={10} style={{ padding: "1.3rem 0rem" }}>
            <Space direction="vertical" size={20} className="pb-5">
              <Space direction="vertical" style={{ width: "100%" }}>
                <label
                  className="font-size-16 font-weight-400"
                  style={{ color: Colors.Label }}
                >
                  {t("fields.selectContacts")}
                </label>
                <Form.Item
                  name={"phoneNumber"}
                  rules={[
                    {
                      required: true,
                      message: "Please select at least one contact",
                    },
                  ]}
                >
                  <Select
                    ref={selectRef}
                    mode="multiple"
                    style={{ width: "100%" }}
                    className="custom-select"
                    suffixIcon={
                      bulkLoading || isSearching ? (
                        <Spin size="small" />
                      ) : (
                        <SearchOutlined />
                      )
                    }
                    loading={bulkLoading || isSearching}
                    placeholder="Please select"
                    onInputKeyDown={(e) => {
                      if (e.key === "Enter" && (bulkLoading || isSearching)) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && (bulkLoading || isSearching)) {
                        e.stopPropagation();
                        e.preventDefault();
                      }
                    }}
                    onChange={handleContactSelectChange}
                    onSelect={handleContactSelect}
                    onClick={() => {
                      dispatch(
                        RequestAppAction.fetchBulkContacts({
                          id: did,
                          meta: { ...PAGINATION },
                          cbFailure: (e: string) => {
                            notification.error({
                              message: e ?? t("notification.fetchContactFail")
                            });
                          },
                        })
                      );
                    }}
                    onSearch={(val) => {
                      setValue(val);
                    }}
                    filterOption={false}
                    options={selectedContacts.map((item: any) => ({
                      label: item?.firstName ? (
                        <div>
                          {item?.firstName + "   " + (item?.lastName || "")}
                        </div>
                      ) : (
                        item?.phoneNumber
                      ),
                      value: item?.phoneNumber,
                      key: item?.phoneNumber,
                    }))}
                  />
                </Form.Item>
              </Space>

              <div className="d-flex align-items-end pb-5">
                <Space
                  direction="vertical"
                  style={{ width: "100%", position: "relative" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <label
                      className="font-size-16 font-weight-400"
                      style={{ color: Colors.Label }}
                    >
                      {t("fields.message")}
                    </label>
                    <div className="d-flex">
                      <div>
                        {t("sideBar.chars")} {characters}/{MSG_CHAR_LIMIT}
                      </div>
                      <div style={{ marginRight: "0.2rem" }} className="pl-5">
                        {t("sideBar.msgUnits")} {units}/{MSG_UNIT_LIMIT}
                      </div>
                    </div>
                  </div>
                  <Form.Item
                    name={"content"}
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please enter a message",
                      // },
                      {
                        validator: (_, value) => {
                          if (!!value || attachments?.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Please enter a message");
                        },
                      },
                    ]}
                  >
                    <Input.TextArea
                      ref={textareaRef}
                      style={{ resize: "none" }}
                      rows={10}
                      onChange={handleTextChange}
                      placeholder="Type your text here..."
                    />
                  </Form.Item>

                  <ModalButtonContainer>
                    {width > 576 && (
                      <AddVariableBtn
                        disabled={!variableList || variableList.length === 0}
                        variableList={variableList}
                        form={form}
                        textareaRef={textareaRef}
                        message={message}
                        onTextChange={onTextChange}
                        style={{ borderRadius: 50, marginLeft: 10 }}
                      />
                    )}
                    <TemplateBtn
                      templateMenu={menu}
                      style={{ borderRadius: 50, marginLeft: 10 }}
                    />
                    <ScheduleBtn
                      form={form}
                      setSelectedPhoneNumbers={setSelectedPhoneNumbers}
                      openScheduleMsgModal={openScheduleMsgModal}
                      style={{ borderRadius: 50, marginLeft: 10 }}
                    />
                    <AttachmentBtn
                      uploadFileRef={uploadFileRef}
                      style={{ borderRadius: 50, marginLeft: 10 }}
                    />
                    <SendButton
                      isDisabled={units > 10 || isLoading || loading}
                      isLoading={units > 10 ? false : (isLoading || loading)}
                      tooltipTitle={units > 10 ? t("message.unitError") : ""}
                    />
                  </ModalButtonContainer>
                </Space>
              </div>
              {attachments?.length > 0 && (
                <Col
                  style={{
                    display: "flex",
                  }}
                  span={24}
                >
                  {attachments?.map((i: string, ind: number) => (
                    <div
                      style={{ position: "relative", marginRight: "0.5rem" }}
                    >
                      <Tag
                        onClick={() => {
                          window.open(i, "_blank");
                        }}
                        style={{ background: Colors.white, borderRadius: 4 }}
                      >
                        <Text
                          key={ind}
                          className="font-size-14"
                          style={{
                            display: "flex",
                          }}
                        >
                          {returnElipsesText(i)}
                        </Text>
                      </Tag>
                      <CloseCircleFilled
                        onClick={() => {
                          const arr = attachments;
                          setAttachments(arr.filter((item: any) => item !== i));
                        }}
                        style={{
                          color: Colors.red,
                          position: "absolute",
                          top: -10,
                          right: 0,
                          zIndex: 2,
                        }}
                      />
                    </div>
                  ))}
                </Col>
              )}
            </Space>
          </Flex>
        </Form>
      </Spin>
      <AddBulkScheduleMessageModal
        modalRef={scheduleBulkMessageRef}
        setSelectedPhoneNumber={setSelectedPhoneNumbers}
        phoneNumbers={selectedPhoneNumbers}
        content={msgContent}
        bulkContacts={bulkContacts}
        images={attachments}
      />
    </Modal>
  );
};

export default BulkMessageModal;
