import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Input, Select, SelectProps, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { PHONENUMBERREGEX, selectFilterOption } from "src/constants/common";
import { RequestedDidNumberType } from "src/constants/subscription";
import { capitalizeFirstLetter } from "src/utils/helper-functions";
import { formatNumberStringEN } from "src/utils/format";
import styles from "src/pages/subscribe/subscribe.module.scss";

const { Text } = Typography;

interface ICreateNumberForm {
  form: any;
  i: number;
  j: number;
  isUserExpand: boolean;
  numDids: number;
  numberTypes: any;
  setNumberTypes: (numberType: any) => void;
  initialLoad: boolean;
}

const CreateNumberForm = ({
  form,
  i,
  j,
  isUserExpand = false,
  numDids,
  numberTypes,
  setNumberTypes,
  initialLoad,
}: ICreateNumberForm) => {
  const { t } = useTranslation();

  const [isExpand, setIsExpand] = useState<boolean>(true);
  const [existingOrRequestingNumber, setExistingOrRequestingNumber] = useState<boolean>(true);
  const [numberType, setNumberType] = useState<boolean>(true);
  const [selectedRequestNumber, setSelectedRequestNumber] = useState<string>("");
  const [reuseNumOptions, setReuseNumOptions] = useState<SelectProps['options']>([]);

  const didRequiredFields = {
    addNumber: true,
    specialRequests: false,
  };

  const getOptionsList = () => {
    if (!numberTypes) {
      return;
    }

    const requestedNumbers = Object.keys(numberTypes).filter((key) =>
      key.startsWith(`user_1_did_`)
    );

    const numOptions: any[] = [];
    let prevNumIndex = 0;

    requestedNumbers.forEach((requestedNum, index) => {
      if (numberTypes[requestedNum] === RequestedDidNumberType.EXISTING) {
        return;
      }

      const parts = requestedNum.split('_');

      if (parts.length > 3) {
        const numIndex = parseInt(parts[3], 10);

        if (!isNaN(numIndex) && numIndex > prevNumIndex) {
          numOptions.push(
            {
              key: index,
              label: t(
                "subscribeStep5.nthNumber",
                { number: capitalizeFirstLetter(formatNumberStringEN(numIndex)) }
              ),
              value: numIndex,
            }
          );
          prevNumIndex = numIndex;
        }
      }
    });

    return numOptions;
  };

  // useEffect(() => {
  //   if (!reuseNumOptions || reuseNumOptions.length === 0) {
  //     const options = getOptionsList();
  //     setReuseNumOptions(options);
  //   }
  // }, []);

  useEffect(() => {
    if (initialLoad) return;

    const addNumber = form.getFieldValue(`user_${i}_did_${j}_addNumber`);
    const requestedNumber = form.getFieldValue(`user_${i}_did_${j}_requestedNumber`);
    const newNumberTypes = numberTypes;

    if (requestedNumber && requestedNumber > 0) {
      newNumberTypes[`user_${i}_did_${j}_numberType`] = RequestedDidNumberType.REQUESTED;
    } else if (addNumber && addNumber.length > 0) {
      setExistingOrRequestingNumber(true);
      newNumberTypes[`user_${i}_did_${j}_numberType`] = RequestedDidNumberType.EXISTING;
    } else {
      setExistingOrRequestingNumber(false);
      newNumberTypes[`user_${i}_did_${j}_numberType`] =
        numberType
        ? RequestedDidNumberType.LOCAL
        : RequestedDidNumberType.TOLLFREE;
    }

    setNumberTypes(newNumberTypes);
  }, [initialLoad, numberType]);

  useEffect(() => {
    if (initialLoad) return;

    const requestedNumber = form.getFieldValue(`user_${i}_did_${j}_requestedNumber`);
    const formNumberType = numberTypes[`user_${i}_did_${j}_numberType`];

    if (requestedNumber && requestedNumber > 0) {
      setExistingOrRequestingNumber(false);
      form.setFieldValue(
        `user_${i}_did_${j}_numberType`,
        RequestedDidNumberType.REQUESTED
      );
      form.setFieldValue(
        `user_${i}_did_${j}_requestedNumber`,
        requestedNumber
      );
      setSelectedRequestNumber(requestedNumber);
    } else if (formNumberType === RequestedDidNumberType.LOCAL) {
      setExistingOrRequestingNumber(false);
      setNumberType(true);
    } else if (formNumberType === RequestedDidNumberType.TOLLFREE) {
      setExistingOrRequestingNumber(false);
      setNumberType(false);
    } else {
      setExistingOrRequestingNumber(true);
      form.setFieldValue(
        `user_${i}_did_${j}_numberType`,
        RequestedDidNumberType.EXISTING
      );
      form.resetFields([`user_${i}_did_${j}_specialRequests`]);
    }

    const options = getOptionsList();
    setReuseNumOptions(options);
  }, [initialLoad, numDids]);

  const onChangeSelectedRequestNumber = (value: string) => {
    setSelectedRequestNumber(value);

    if (value && value !== '') {
      form.resetFields([`user_${i}_did_${j}_specialRequests`]);

      const newNumberTypes = numberTypes;
      newNumberTypes[`user_${i}_did_${j}_numberType`] = RequestedDidNumberType.REQUESTED;
      setNumberTypes(newNumberTypes);
    } else {
      setNumberType(true);
    }
  };

  const resetFields = () => {
    if (existingOrRequestingNumber) {
      form.resetFields([`user_${i}_did_${j}_addNumber`]);
    } else {
      form.resetFields([`user_${i}_did_${j}_specialRequests`]);
    }
    setExistingOrRequestingNumber(!existingOrRequestingNumber);
    setNumberType(true);
  };

  const onChangeNumberType = () => {
    setNumberType(!numberType);
  };

  const validatePhoneNumber = () => {
    const number = form.getFieldValue(`user_${i}_did_${j}_addNumber`);
    const trimmedValue = number ? number.trim() : "";

    if (!trimmedValue || trimmedValue.length === 0) {
      return Promise.resolve();
    }

    if (!PHONENUMBERREGEX.test(trimmedValue)) {
      return Promise.reject(new Error(t("fields.addNumberRestriction")));
    }

    if (trimmedValue.length !== 10) {
      return Promise.reject(new Error(t("fields.addNumberLength")));
    }

    for (let k = 1; k <= numDids; k++) {
      if (k !== j) {
        const value = form.getFieldValue(`user_${i}_did_${k}_addNumber`);
        if (value === trimmedValue) {
          return Promise.reject(new Error(
            t(
              "fields.addNumberAlreadyInUse",
              { number: capitalizeFirstLetter(formatNumberStringEN(k)) }
            )
          ));
        }
      }
    }

    return Promise.resolve();
  };

  return (
    <div
      className={`${styles.sign_up_form_item} ${styles.sign_up_form} ${styles.sign_up_form_accordion_section} ${isUserExpand ? "" : styles.hidden}`}
    >
      <div
        style={{ position: "relative" }}
        className={styles.sign_up_form_item}
      >
        <Text className={styles.sign_up_form_section_title}>
          {t(
            "subscribeStep3.numberInfo",
            {
              userNumber: capitalizeFirstLetter(formatNumberStringEN(i)),
              numNumber: capitalizeFirstLetter(formatNumberStringEN(j)),
            }
          )}
        </Text>
        <Button
          className={styles.sign_up_form_accordion_btn}
          icon={
            isExpand ? ( <UpOutlined /> ) : ( <DownOutlined /> )
          }
          onClick={() => setIsExpand(!isExpand)}
        >
        </Button>
      </div>
      <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
        <label>{t("fields.enablingExistingOrNewNumber")}</label>
        <div style={{ marginTop: "8px" }} className={styles.checkboxes}>
          <Checkbox
            checked={existingOrRequestingNumber}
            onChange={resetFields}
          >
            {t("fields.existingNumber")}
          </Checkbox>
          <Checkbox
            checked={!existingOrRequestingNumber}
            onChange={resetFields}
          >
            {t("fields.requestNewNumber")}
          </Checkbox>
        </div>
      </div>
      {existingOrRequestingNumber ? (
        <Form.Item
          name={`user_${i}_did_${j}_addNumber`}
          label={t("fields.addNumber")}
          className={
            `${styles.sign_up_form_item} ${didRequiredFields.addNumber ? styles.required : ""} ${isExpand ? "" : styles.hidden}`
          }
          rules={[
            {
              required: existingOrRequestingNumber && didRequiredFields.addNumber,
              message: t("fields.addNumberRequired")
            },
            { validator: () => validatePhoneNumber() },
          ]}
        >
          <Input
            placeholder={t("fields.addNumber")}
            className={styles.sign_up_form_input}
          />
        </Form.Item>
      ) : (
        <>
          {i > 1 && (
            <Form.Item
              name={`user_${i}_did_${j}_requestedNumber`}
              label={t("fields.useRequestedNumber")}
              className={styles.sign_up_form_item}
            >
              <Select
                allowClear
                showSearch
                placeholder={t("fields.selectRequestedNumber")}
                value={selectedRequestNumber}
                onChange={onChangeSelectedRequestNumber}
                options={reuseNumOptions}
                filterOption={selectFilterOption}
                className={`${styles.sign_up_form_input} ${styles.sign_up_form_select}`}
              />
            </Form.Item>
          )}

          {(!selectedRequestNumber || selectedRequestNumber === "") && (
            <>
              <div className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}>
                <label>{t("fields.typeOfNumber")}</label>
                <div style={{ marginTop: "8px" }} className={styles.checkboxes}>
                  <Checkbox
                    checked={numberType}
                    onChange={onChangeNumberType}
                  >
                    {t("fields.localNumber")}
                  </Checkbox>
                  <Checkbox
                    checked={!numberType}
                    onChange={onChangeNumberType}
                  >
                    {t("fields.tollFreeNumber")}
                  </Checkbox>
                </div>
              </div>
              <Form.Item
                name={`user_${i}_did_${j}_specialRequests`}
                label={t("fields.specialRequests")}
                className={`${styles.sign_up_form_item} ${isExpand ? "" : styles.hidden}`}
              >
                <Input
                  placeholder={t("fields.specialRequests")}
                  className={styles.sign_up_form_input}
                />
              </Form.Item>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CreateNumberForm;
