import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RequestedDidNumberType } from "src/constants/subscription";
import EditUserBlock from "./EditUserBlock";
import styles from "src/pages/subscribe/subscribe.module.scss";

interface IEditUserInfo {
  form: any;
  setEditUserInfo: (editUserInfo: boolean) => void;
  userData?: any;
  setUserData: (payloadData: any) => void;
  signUpUserFormData?: any;
  setSignUpUserFormData: (signUpUserFormData: any) => void;
  numUsersDids: any;
  onCancel: () => void;
}

const EditUserInfo = ({
  form,
  setEditUserInfo,
  userData,
  setUserData,
  signUpUserFormData,
  setSignUpUserFormData,
  numUsersDids,
  onCancel,
}: IEditUserInfo) => {
  const { t } = useTranslation();

  const [numUsers, setNumUsers] = useState<number>(1);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    if (!initialLoad) return;

    if (signUpUserFormData) {
      form.setFieldsValue(signUpUserFormData);

      if (numUsersDids && Object.keys(numUsersDids)) {
        setNumUsers(Object.keys(numUsersDids).length);
      }

      setInitialLoad(false);
    }
  }, [signUpUserFormData]);

  const onFinish = (values: any) => {
    let newSignUpUserFormData: any = signUpUserFormData;
    newSignUpUserFormData = {
      ...newSignUpUserFormData,
      ...values,
    };
    setSignUpUserFormData(newSignUpUserFormData);

    const payloadData: any = userData;

    for (let i = 1; i <= numUsers; i++) {
      payloadData.users[i - 1].username = values[`user_${i}_username`];
      payloadData.users[i - 1].name = values[`user_${i}_contactName`];
      payloadData.users[i - 1].email = values[`user_${i}_email`];

      const numDids = numUsersDids[i] || 1;
      for (let j = 1; j <= numDids; j++) {
        payloadData.users[i - 1].dids[j - 1].did = values[`user_${i}_did_${j}_addNumber`];
        payloadData.users[i - 1].dids[j - 1].specialRequests = values[`user_${i}_did_${j}_addNumber`];

        const numberType = form.getFieldValue(`user_${i}_did_${j}_numberType`);
        if (numberType === RequestedDidNumberType.REQUESTED) {
          const specialRequests = values[`user_${i}_did_${j}_requestedNumber`];
          payloadData.users[i - 1].dids[j - 1].specialRequests = specialRequests;
        } else {
          const specialRequests = values[`user_${i}_did_${j}_specialRequests`];
          payloadData.users[i - 1].dids[j - 1].specialRequests = specialRequests;
        }
      }
    }

    setUserData(payloadData);
    setEditUserInfo(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className={styles.sign_up_form}
      >
        {[...Array(numUsers)].map((_, i: number) => (
          <React.Fragment key={`user-${i + 1}`}>
            <EditUserBlock
              form={form}
              n={i + 1}
              numUsers={numUsers}
              numUsersDids={numUsersDids}
              initialLoad={initialLoad}
            />
          </React.Fragment>
        ))}
        <Form.Item className={`${styles.review_item} ${styles.review_btn}`}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.primary}
            onClick={() => onFinish(form.getFieldsValue())}
          >
            {t("button.save")}
          </Button>
        </Form.Item>
        <Form.Item className={`${styles.review_item} ${styles.review_btn}`}>
          <Button
            style={{
              marginTop: "12px",
              border: "1px solid #FAEBD5"
            }}
            className={styles.secondary}
            onClick={onCancel}
          >
            {t("button.cancel")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUserInfo;
